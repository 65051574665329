import { environment } from 'src/environments/environment';
import { LOCATION_INITIALIZED, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { JwtInterceptor } from './services/auth/jwt.interceptor';
import { AwsRumService } from './services/aws-rum/aws-rum.service';
import { NavigationService } from './services/navigation/navigation.service';
import { StartUpService } from './services/startup/startup.service';
import { UserService } from './services/user/user.service';

/* istanbul ignore next */
function initData(startupService: StartUpService, userService: UserService) {
  if (userService.getAuthToken() !== null) {
    startupService.init();
  }
}

/* istanbul ignore next */
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/* istanbul ignore next */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function appInitializerFactory(translate: TranslateService, injector: Injector): () => Promise<any> {
  /* istanbul ignore next */
  return () =>
    new Promise((resolve) => {
      injector.get(LOCATION_INITIALIZED, Promise.resolve(null)).then(() => {
        const browserLang = translate.getBrowserLang();
        // Add here additional supported languages e.g. en|de
        translate.use(browserLang && browserLang.match(/en/) ? browserLang : 'en').subscribe({
          complete: () => {
            // init data after translation service was initialized as some services require translations
            const startupService = injector.get(StartUpService);
            const userService = injector.get(UserService);
            initData(startupService, userService);
            resolve(null);
          },
        });
      });
    });
}

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgOptimizedImage,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.development,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    AwsRumService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, NavigationService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: Window, useValue: window },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
