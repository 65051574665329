/* eslint-disable @typescript-eslint/no-explicit-any */
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { GlobalStoreService } from '../global-store/global-store.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private readonly userService: UserService,
    private readonly globalSoreService: GlobalStoreService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken: string | null = this.userService.getAuthToken();

    // If authorization header exists, don't alter the request
    if (request.headers.has('authorization')) {
      return next.handle(request);
    }

    // If there is no JWT token, don't alter the request
    // For retrieving the token (the first call)
    if (!authToken) {
      return next.handle(request);
    }

    const withAuthRequest = this.addTokenHeader(request, authToken);

    return next.handle(withAuthRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle unauthorized HTTP errors
        if (error.status === 401 && !withAuthRequest.url.includes('oauth2/token')) {
          return throwError(() => error);
        }
        // Handle forbidden HTTP errors (token not valid)
        else if (error.status === 403 || error.status === 504) {
          return this.handleTokenExpired(withAuthRequest, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  handleTokenExpired(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.globalSoreService.isTokenRefreshing.next(true);
      this.tokenSubject.next(null);
      this.userService.removeAuthentication();

      return this.userService.authenticate().pipe(
        switchMap((token: string | null) => {
          this.isRefreshing = false;
          this.tokenSubject.next(token);
          return next.handle(this.addTokenHeader(request, token));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          return throwError(() => error);
        })
      );
    }

    return this.tokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, authToken: string | null): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}
